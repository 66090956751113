import { API_GATEWAY_ENDPOINT } from "util/env";
import { API_GATEWAY_PATIENT_ENDPOINT } from "util/env";

import Axios, { AxiosRequestConfig } from "axios";

export const AXIOS_INSTANCE = Axios.create({
  // eslint-disable-next-line no-undef
  baseURL: API_GATEWAY_ENDPOINT,
});

export const PATIENT_AXIOS_INSTANCE = Axios.create({
  // eslint-disable-next-line no-undef
  baseURL: API_GATEWAY_PATIENT_ENDPOINT,
});

const ID_TOKEN_KEY = "idToken";

export const useCustomClient = <T>(): ((
  config: AxiosRequestConfig
) => Promise<T>) => {
  const token = localStorage.getItem(ID_TOKEN_KEY);

  return (config: AxiosRequestConfig & { tag?: string }) => {
    const source = Axios.CancelToken.source();

    // patient用のAPI Gatewayの場合はエンドポイントを切り替える
    const isPatientApiGateway = config?.url?.match(`^/[^/]+/apiGatewayPatient(/|$)`);

    if(isPatientApiGateway) {
      const promise = PATIENT_AXIOS_INSTANCE({
        ...config,
        cancelToken: source.token,
        headers: { Authorization: `Bearer ${token}` },
      }).then(({ data }) => data);
  
      // @ts-ignore
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise;
    } else {
      const promise = AXIOS_INSTANCE({
        ...config,
        cancelToken: source.token,
        headers: { Authorization: `Bearer ${token}` },
      }).then(({ data }) => data);
  
      // @ts-ignore
      promise.cancel = () => {
        source.cancel("Query was cancelled by React Query");
      };
      return promise;
    }
  };
};

export default useCustomClient;

export type ErrorType<ErrorData> = ErrorData;

export type BodyType<BodyData> = BodyData & { headers?: any };
